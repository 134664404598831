import client from "../libs/client";
import {roles} from "../../../config/app-config";
import {authenticationGatewayInterface} from "../../../corelogic/usecases/authentication/authenticationGatewayInterface";
import {User} from "../../../corelogic/models/entities/authentication/user";

// TODO gérer plus tard, pour l'instant on utilise InMemoryAuthenticationGateway

export default class APIAuthenticationGateway implements authenticationGatewayInterface {
    //todo créer User

    getUser = async () => {
        const { data: user } = await client.get('/session');
        if (user.role === roles.PARTICIPANT) {
            const { data: [{ energyType }] } = await client.get(`/participants/by-jwt`);
            user.energyType = energyType;
        }
        return new User(user.id, user.role, user.email, user.initialized, user.hasAcceptedCgu, user.energyType);
    };

    logIn = async ({ email, password} : {email:string, password:string}) => {
        const {data: res} = await client.post('/session/login', {
            email,
            password,
        });
        return res;
    }

    logOut = async () => {
        const res = await client.get('/session/logout');
        return res.data
    }

    acceptCgu = async (cguId:any) => {
        const userCgu = await client.post(`cgu/accept-cgu/${cguId}`);
        return userCgu
    }

    changePassword = async ({oldPassword, newPassword}: { oldPassword : string, newPassword : string }) => {
        const res = await client.put(`/users/update-pw-with-old`, {oldPassword, newPassword})
            .then( data => {
                // Update organizer in database
                return client.put(`/users/update-authenticated-user`, {initialized: true});
            })
        return res.data
    }
}
